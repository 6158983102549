.bgImage {
    background-image: url('./../../assets/Introduction/23-8-23-11.png');
    background-size: cover;
    background-position: center;
    position: relative;
    height: 75vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background-attachment: fixed;
}

.overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.50);
    z-index: 2;
}

.list {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    color: #FFF;
    justify-content: center;
list-style: none;
padding: 0;
    /* لإزالة نقاط القائمة الافتراضية */
}

.list li::before {
    content: '\2022';
    /* رمز النقطة (يمكن استخدام \2022 أو \2023) */
    color: #7FCAE7;
    /* لون النقطة الجديد */
    display: inline-block;
    width: 1rem;
    margin-left: 0em;
}