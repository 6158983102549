.bg-contact{
            background-image: url('./../../assets/contacts/laptop-with-many-envelopes.png');
            height: 65vh;
            background-repeat: no-repeat;
            background-size: cover;
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
}
a {
    color: #fff;
    text-decoration: none;
}
.social {
position: absolute;
    top: 35%;
    left: 0;
}
.socialAr{
    position: absolute;
        top: 35%;
        right: 0;
}
.social ul {
    padding: 0px;
    transform: translate(-250px, 0);
}
.socialAr ul {
    padding: 0px;
        transform: translate(250px, 0);
}
.social ul li {
    display: block;
    margin: 5px;
    background: rgba(0, 0, 0, 0.36);
    width: 300px;
    text-align: right;
    padding: 10px;
    border-radius: 0 30px 30px 0;
    transition: all 1s;
}
.socialAr ul li {
    display: block;
    margin: 5px;
    background: rgba(0, 0, 0, 0.36);
    width: 300px;
    text-align: left;
    padding: 10px;
    border-radius: 0 30px 30px 0;
    transition: all 1s;
}

.social ul li:hover {
    transform: translate(110px, 0);
    background: rgba(255, 255, 255, 0.4);
}
.socialAr ul li:hover {
    transform: translate(-110px, 0);
    background: rgba(255, 255, 255, 0.4);
}

.social ul li:hover a {
    color: #000;
}

.social ul li:hover img {
    color: #fff;
    background: rgba(0, 0, 0, 0.36);
    transform: rotate(360deg);
    transition: all 1s;
}

.social ul li img {
margin-left: 10px;
    color: #000;
    background: #fff;
    padding: 10px 15px;
    border-radius: 6px;
    width: 15%;
    height: 15%;
    transform: rotate(0deg);
    object-fit: contain;
}
.socialAr ul li:hover a {
    color: #000;
}

.socialAr ul li:hover img {
    color: #fff;
    background: rgba(0, 0, 0, 0.36);
    transform: rotate(360deg);
    transition: all 1s;
}

.socialAr ul li img {
margin-left: 10px;
    color: #000;
    background: #fff;
    padding: 10px 15px;
    border-radius: 6px;
    width: 15%;
    height: 15%;
    transform: rotate(0deg);
    object-fit: contain;
}