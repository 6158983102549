.bgImage{
    background-image: url('./../../assets/home/deve/bg.png');
        background-size: cover;
        background-position: center;
        position: relative;
        height: 75vh;
        display: flex;
        align-items: center;
        justify-content: center;
        background-attachment: fixed;
}

.overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.50);
    z-index: 2;
}