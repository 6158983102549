.header {
    background-image: url('./../../assets/construction-worker-truss-installation.png');
    height: 35vh;
    background-repeat: no-repeat;
    background-size: cover;
        position: relative;
            display: flex;
            justify-content: center;
            align-items: center;

}

.overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(16, 68, 111, 0.50);
    z-index: 2;
}